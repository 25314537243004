.vision-one {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.vision-one-top {
    padding: 1rem;
    line-height: 1.6;
    width: 40%;
}

.vision-img {
    width: 35%;
}

@media only screen and (max-width: 576px) {
    .vision-one{
        display: flex;
        flex-direction: column;
    }

    .vision-one-top {
        padding: 1rem;
        line-height: 1.6;
        width: 80%;
    }
    .vision-img {
        width: 80%;
    }
  }