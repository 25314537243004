.about-container {
    height: 100vh;
    width: 100vw;
}

.about-top {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}