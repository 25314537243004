.carousel {
    width: 100vh;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.slide {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: relative;
}

.caption {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}